import React, { Component, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  useEffect(() => {
    const $ = window.$;
    $("body").removeClass("home-3");
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "logo";
  let anchor = "#";
  return (
    <div className="navbar-area">
      {/* navbar top start */}
      <div className="navbar-top ">
        <div className="container-fluid mlr-25">
          <div className="row">
            <div className="col-md-3">
              <div className="flxx">
                <Marquee
                  pauseOnHover={true}
                  delay={1}
                  speed={40}
                  gradientWidth={100}
                >
                  <p className="mb-0 pr-50 marQP">
                    "Opportunities don't happen, you create them.”
                  </p>
                </Marquee>{" "}
                <i className="fa fa-envelope-o" />
              </div>
            </div>
            <div className="col-md-9 text-md-right text-center pho-none">
              <ul>
                <li>
                  <p>
                    <i className="fa fa-map-marker" /> St. Joseph Matric Hr. Sec
                    School Ondipudur, Coimbatore-641033, TamilNadu.
                  </p>
                </li>
                <li>
                  <p>
                    <i className="fa fa-envelope-o" /> acmatricschools@gmail.com
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <nav
        className={`navbar navbar-area-1 navbar-area navbar-expand-lg ${
          location.pathname !== "/" ? "bgWhite" : ""
        }`}
      >
        <div className="container-fluid nav-container">
          <div className="responsive-mobile-menu">
            <button
              className="menu toggle-btn d-block d-lg-none"
              data-target="#edumint_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link to="/">
              <img
                src={publicUrl + "assets/img/logo.png"}
                width="150px"
                alt="img"
              />
            </Link>
          </div>

          <div
            className="collapse navbar-collapse go-top"
            id="edumint_main_menu"
          >
            <ul className="navbar-nav menu-open">
              <li className={location.pathname === "/" ? "activeLI" : ""}>
                <Link to="/">About</Link>
              </li>
              <li
                className={
                  location.pathname === "/office-bearers" ? "activeLI" : ""
                }
              >
                <Link to="/office-bearers">Governing Body</Link>
              </li>
              <li
                className={
                  location.pathname === "/executive-committee" ? "activeLI" : ""
                }
              >
                <Link to="/executive-committee">Executive Committee</Link>
              </li>
              <li
                className={location.pathname === "/directory" ? "activeLI" : ""}
              >
                <Link to="/directory">Directory </Link>
              </li>

              <li
                className={location.pathname === "/gallery" ? "activeLI" : ""}
              >
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="#">Government Orders</Link>
              </li>
              <li className=" menu-item-has-children current-menu-item">
                <Link to="#">Procedures & Forms</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/procedures-forms">Procedures & Forms</Link>
                  </li>
                  <li>
                    <Link to="/board-exam">Board Exam</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Teachers Resume</Link>
                  </li> */}
                </ul>
              </li>
            </ul>
            {/* <ul className="navbar-nav menu-open">
                <li className="menu-item-has-children current-menu-item">
                  <Link to="/">Home</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/">Home 01</Link>
                    </li>
                    <li>
                      <Link to="/home-v2">Home 02</Link>
                    </li>
                    <li>
                      <Link to="/home-v3">Home 03</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/course">Course</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/course">Course</Link>
                    </li>
                    <li>
                      <Link to="/course-details">Course Single</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">Pages</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/event">Event</Link>
                    </li>
                    <li>
                      <Link to="/event-details">Event Details</Link>
                    </li>
                    <li>
                      <Link to="/instructor">Instructor</Link>
                    </li>
                    <li>
                      <Link to="/instructor-details">Instructor Details</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/sign-in">Sign In</Link>
                    </li>
                    <li>
                      <Link to="/sign-up">Sign Up</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/blog">Blog</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/blog-details">Blog Details</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul> */}
          </div>
          {/* <div className="nav-right-part nav-right-part-desktop">
                  <Link className="signin-btn" to="/sign-in">Sign In</Link>
                  <Link className="btn btn-base" to="/sign-up">Sign Up</Link>
                  <a className="search-bar" href="#"><i className="fa fa-search" /></a>
                </div> */}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
